<script>
import { mapState, mapActions } from 'vuex';
import FormatUtil from '@/utils/FormatUtil';
import TheSidebar from '@/views/common/TheSidebar';
import ThePageHeadingBar from '@/components/ThePageHeadingBar';

export default {
    name: 'Subscriptions',
    components: {
        ThePageHeadingBar,
        TheSidebar,
    },
    data() {
        return {
            subscriptionHeaders: [
                { text: 'Name', value: 'friendly' },
                { text: 'Product Code', value: 'productCode' },
                { text: 'Vendor', value: 'digitalVendorName' },
                { text: 'Cost', value: 'costAmount' },
                { text: 'Number of Libraries', value: 'numberOfLibraries', align: 'center' },
                { text: 'Libraries', value: 'libraries' },
            ],
            libraryHeaders: [
                { text: 'Name', value: 'friendly' },
                { text: 'Managed By', value: 'manuallyManaged' },
                { text: 'Active', value: 'active', align: 'end'},
            ],
        };
    },
    computed: {
        ...mapState({
            subscriptions: (state) => state.Subscription.subscriptions,
        }),
    },
    created() {
        this.fetchSubscriptions();
    },
    methods: {
        ...mapActions({
            fetchSubscriptions: 'Subscription/fetchSubscriptions',
        }),
        formatCurrency(price) {
            if (price === null) return '';
            return `$${FormatUtil.toCurrency(price)}`;
        },
    },
};
</script>

<template>
    <div class="sidebar-thin custom-container">
        <TheSidebar />

        <ThePageHeadingBar pageHeading="Subscriptions Management" />

        <section class="main">
            <!-- Top Page Information -->
            <div class="page-info-top result-count-bottom-border my-5">
                {{ subscriptions.length || 0 }} Subscriptions Found
            </div>

            <v-data-table
                :headers="subscriptionHeaders"
                :items="subscriptions"
                :items-per-page="20"
            >
                <template #[`item.costAmount`]="{ item }">
                    {{ formatCurrency(item.costAmount) }}
                </template>

                <template #[`item.numberOfLibraries`]="{ item }">
                    {{ item.subscriptionLibraryDtos.length }}
                </template>

                <template #[`item.libraries`]="{ item }">
                    <v-dialog
                        v-model="item.dialog"
                        width="600"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                small
                                elevation="0"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                                View
                            </v-btn>
                        </template>

                        <v-card>
                            <v-card-title class="text-h5 grey lighten-2">
                                Subscription
                                <v-spacer></v-spacer>
                                <v-btn icon @click="item.dialog = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-card-title>

                            <v-card-text>
                                <v-data-table
                                    :headers="libraryHeaders"
                                    :items="item.subscriptionLibraryDtos"
                                    :items-per-page="-1"
                                    hide-default-footer
                                >
                                    <template #[`item.manuallyManaged`]="{ item }">
                                        {{ item.manuallyManaged ? 'Slingshot' : 'Vendor' }}
                                    </template>

                                    <template #[`item.active`]="{ item }">
                                        <v-icon>{{ item.active ? 'mdi-check' : 'mdi-close' }}</v-icon>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </template>
            </v-data-table>
        </section>
    </div>
</template>
